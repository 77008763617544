import {
  TitleBoldWrapper,
  RowElementWrapper,
  RowItemLabel,
  Fill,
  RowItem,
  PriceBadge,
  LongRowItemLabel,
} from './styles';

export interface IOptionLabel {
  label: string;
  price: string;
  unit: string;
  longRowItem?: boolean;
}

export interface IOptionalInsuranceCardProps {
  contentTitle: string;
  options: IOptionLabel[];
}

export interface IPriceDetails {
  assuKey?: string;
  monthlyCost: string;
  totalCost: string;
  yearlyInsuranceAmount: string;
}

export interface IInsuranceCmpProps {
  onSelectOption: (value?: string) => void;
  onReturnClick: () => void;
  value: string;
  priceDetails: IPriceDetails;
}

const OptionalInsuranceCard = ({
  contentTitle,
  options,
}: IOptionalInsuranceCardProps) => {
  return (
    <base-card>
      {contentTitle && (
        <TitleBoldWrapper level={2}>{contentTitle}</TitleBoldWrapper>
      )}
      {options.map((item, index) => (
        <base-card-row key={`${item.label}_${index + 1}`}>
          <RowElementWrapper>
            {item.longRowItem ? (
              <LongRowItemLabel>{item.label}</LongRowItemLabel>
            ) : (
              <RowItemLabel>{item.label}</RowItemLabel>
            )}
            <Fill />
            <RowItem>
              <PriceBadge>
                {`${item.price}`.replace('.', ',')}
                {item.unit}
              </PriceBadge>
            </RowItem>
          </RowElementWrapper>
        </base-card-row>
      ))}
    </base-card>
  );
};

export default OptionalInsuranceCard;
