import React from 'react';
import ErrorComponent from './ErrorComponent';
import { IRoutingContext } from '@sweb-front/types';
import { ERRORDETAILS } from '@sweb-front/services';

export interface ErrorBoundaryProps {
  children: React.ReactNode | JSX.Element;
  routingContext?: IRoutingContext[];
}

export interface ErrorBoundaryState {
  hasError: boolean;
}

// ICI on utilise une class (à l'ancienne ouech) parce qu'il n'est pas encore
// possible de faire des errorBoundary en functionnal component avec des hooks
// https://stackoverflow.com/questions/48482619/how-can-i-make-use-of-error-boundaries-in-functional-react-components#:~:text=As%20of%20v16.,%7B%7D%20block%2C%20but%20for%20components.
class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    localStorage.setItem(
      ERRORDETAILS,
      `Problème front: ${error.message ?? ''}`
    );
  }

  render() {
    const { props, state } = this;
    if (state.hasError) {
      return (
        <>
          <base-modal show>
            <ErrorComponent />
          </base-modal>
        </>
      );
    }

    return props.children;
  }
}

export default ErrorBoundary;
