import styled from 'styled-components';
import { RadioButtonCard } from '../DesignSystem';

export interface BadgeProps {
  isRecommended?: boolean;
}

export const CardStyle = {
  margin: '1rem 0 0 0',
};

export const RadioButtonWrapper = styled(RadioButtonCard)`
    --title-background-color: var(--information-500);
    --title-color: #ffffff;
    --content-padding: 3.2rem 0;
    margin-right: 1.6rem;
    margin-top: 1.6rem;
    width: 100%;

    & span[slot='title'] {
      display: inline-block;
      font-family: 'Ubuntu Bold';
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-top: 2px;
    }

    & div[slot='acronym'] {
      display: inline;
      font-family: 'Ubuntu Bold';
      font-size: 1.8rem;
      line-height: 2.6rem;

      & span {
        display: block;
        font-family: 'Open Sans';
        font-size: 1.4rem;
        line-height: 2rem;
        margin: .4rem 0;
      }

      & .description-insurance-selected {
        margin-top: 2.4rem;
        font-family: "Open Sans";
        font-size: 1.2rem;
        font-style: normal;
        line-height: 16px;
      }

      & .totally-in-charged .subtitle,
      & .monthly-in-charged .subtitle {
        font-family: "Open Sans Bold";
        font-size: 14px;
        font-style: normal;        
        line-height: 20px;
      }

      & .totally-in-charged .item, 
      & .monthly-in-charged .item {
        display: inline-block;
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: var(--neutrals-700);
        background: var(--teal-100);
        border-radius: 0.4rem;
        margin-right: 0.4rem;
        padding: 0.4rem 0.8rem;

        &.selected {
            color: #fff;
            background: #007590;
        }
      }
       
      & .no-assu-cover {
        display: flex;
        flex-direction: row;
        align-items:flex-start;
        margin-top: 1.6rem;

        & img {
          margin-right: 0.8rem;       
        }

        & .no-assu-description {
          flex: 1;
          margin:0;
          padding:0;   
          font-family: "Open Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;       
          margin-bottom: .8rem;
          color: var(--neutrals-500);
        }
      }
    }

    & span[slot='pricetag'] {
      margin: 0;
      padding: 0;
      display: inline-block;
      background: none;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 2.4rem;
    }
    
    & a[slot='link'] {
      font-family: 'Open Sans';
      line-height: 1.6rem;
      font-size: 1.4rem;
      color: var(--main-green-500);
      margin-bottom: 0.8rem;
      margin-top: 0.4rem;
    }
  }
`;
export const Title = styled.div`
  margin: 0.25rem 0;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.titleModalDocument};
  font-family: Ubuntu Bold;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  line-height: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const SubTitle = styled.span`
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.5rem;
  margin-left: ${({ theme }) => theme.variables.layout.spaces.small};
  font-family: Ubuntu;
  font-weight: lighter;
  color: var(--neutrals-500);
`;

export const PriceBadge = styled.span<{ isRecommended?: boolean }>`
  border-radius: 1rem;
  background-color: ${(props) => (props.isRecommended ? '#e5f5fd' : '#eff8f0')};
  margin: 0.5rem 0;
  padding: 0.4rem 0.8rem;
  width: fit-content;
  font-weight: bold;
  font-size: 1.4rem;
`;

export const DetailsLink = styled.a`
  color: ${({ theme }) => theme.variables.colors.chartColors.green_700};
  margin: 5px 0;
  text-decoration: underline;
`;

export const Description = styled.span`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
`;
