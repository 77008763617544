import { TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const ButtonActionWrapper = styled.div`
  @media screen and ${TAB} {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 4rem 0;
    & .back-button-wrapper {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
`;

export const StyledValidateButtonWrapper = styled.button<{
  $isDisabled?: boolean;
}>`
  color: var(--neutrals-0);
  border: none;
  border-radius: 4px;
  font-family: Open Sans Bold;
  font-size: 18px;
  line-size: 26px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  margin: 0;
  padding: 1rem 1.5rem 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 0;
  height: 5.6rem; /* 56px */
  cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) =>
    props.$isDisabled ? 'var(--neutrals-300)' : 'var(--main-green-500)'};
  @media screen and ${TAB} {
    width: 11.6rem;
  }

  &#options-validate-button {
    @media screen and ${TAB} {
      min-width: 22rem;
      width: auto;
    }
  }
`;

export const StyledBackButtonWrapper = styled.div`
  height: 5.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
`;

export const ModalLeaveWrapper = styled(ModalWrapper)`
  width: 100%;
`;
