import { useCallback, useEffect, useState } from 'react';
import { AutoCompleteFieldWrapper } from './style';
import * as debounce from 'lodash.debounce';
import {
  replaceAccent,
  concantenedObjectWithComma,
  isStringEmpty,
} from '@sweb-front/utils';
import { executeSearch } from '@vat/services';

export interface IAutoCompleteFieldProps {
  id: string;
  url: string;
  value: string;
  required?: boolean;
  label?: string;
  valid?: boolean;
  placeholder?: string;
  suggestionLabel?: string;
  emptyErrorMessage?: string;
  invalidErrorMessage?: string;
  onSuggestionClick?: () => void;
  onChange: (res: Record<string, unknown>, concatenedRes: string) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement>,
    selected: Record<string, unknown> | undefined,
    val: string
  ) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const AutoCompleteField = ({
  required,
  id,
  url,
  value,
  label,
  valid,
  placeholder,
  suggestionLabel,
  invalidErrorMessage,
  emptyErrorMessage,
  onSuggestionClick,
  onChange,
  onBlur,
  onFocus,
}: IAutoCompleteFieldProps) => {
  const [internalValue, setInternalValue] = useState<string>();
  const [error, setError] = useState<boolean>();
  const [blurred, setBlurred] = useState<boolean>();
  const [successed, setSuccessed] = useState<boolean>();
  const [focused, setFocused] = useState<boolean>();
  const [result, setResult] = useState<Record<string, unknown>[]>();
  const [toHighlight, setToHighlight] = useState<string>();
  const [showResult, setShowResult] = useState<boolean>(false);
  const [selected, setSelected] = useState<Record<string, unknown>>();

  const checkInternalValidity = (recordSelected, valToCheck: string) => {
    const concatenedRes = concantenedObjectWithComma({
      localite: recordSelected.localite,
      codePostal: recordSelected.codePostal,
      pays: recordSelected.pays,
    });
    return concatenedRes === valToCheck;
  };

  const onResetField = () => {
    setInternalValue(null);
    setSelected(null);
    onChange?.(null, '');
  };

  const doDebounce = useCallback(
    debounce(async (value: string) => {
      if (isStringEmpty(value)) {
        setShowResult(false);
        return;
      }
      const response = await executeSearch(
        url,
        replaceAccent(value).replace(/[ ]+/g, ' ').trim()
      );
      setResult(response);
      setShowResult(true);
    }, 300),
    []
  );

  const highlightKeywords = (keywords: string, wordToHighlight: string) => {
    const keywordsSplit = keywords
      .trim()
      .split(',')
      .filter((key) => key?.trim() !== '')
      .map((res) => res.trim().replace('(', '\\)').replace(')', '\\)'));

    const regexp = new RegExp(
      `(${keywords.replace('(', '\\(').replace(')', '\\)')})`,
      'gi'
    );

    if (
      keywordsSplit.length === 1 &&
      regexp.test(wordToHighlight.toLowerCase())
    ) {
      const replaceWord = wordToHighlight.replace(regexp, '#$1#').split('#');
      return replaceWord.map((bw) => {
        if (regexp.test(bw)) {
          return <strong>{bw}</strong>;
        }
        return bw;
      });
    }

    if (keywordsSplit.includes(wordToHighlight)) {
      return <strong>{wordToHighlight}</strong>;
    }
    return wordToHighlight;
  };

  // Manage state when user mouve out the field
  useEffect(() => {
    if (required && blurred) {
      if (!internalValue && value) {
        setSuccessed(true);
        setShowResult(false);
      } else {
        const entryValid =
          selected &&
          !isStringEmpty(internalValue) &&
          checkInternalValidity(selected, internalValue);
        setError(!entryValid);
        setSuccessed(entryValid);
        setShowResult(false);
      }
    } else if (blurred) {
      setSuccessed(true);
      setShowResult(false);
    }
  }, [blurred, internalValue, selected]);

  // reset all state when focused
  useEffect(() => {
    if (focused) {
      setSuccessed(undefined);
      setError(undefined);
    }
  }, [focused]);

  return (
    <AutoCompleteFieldWrapper
      $valid={successed || valid}
      $error={error || valid === false}
    >
      {label?.length >= 0 && (
        <div className="label-wrapper">
          <label htmlFor={id}>{label}</label>
        </div>
      )}

      <div className="icon-container">
        <base-icon icon="search" class="icon-search" />
        <input
          autoComplete="off"
          className="autocomplete-input-search"
          placeholder={placeholder}
          id="input-search"
          onChange={(e) => {
            const { value } = e.target;
            setInternalValue(e.target.value);
            // do normal behavior for any other key
            if (value?.length > 2) {
              setToHighlight(e.target.value);
              doDebounce(e.target.value);
            } else {
              setResult([]);
              setShowResult(false);
            }
            onChange?.(selected, e.target.value);
          }}
          onBlur={(e) => {
            setInternalValue(internalValue);
            setFocused(false);
            setBlurred(true);
            onBlur?.(e, selected, internalValue);
          }}
          onFocus={(e) => {
            setFocused(true);
            setBlurred(false);
            setSuccessed(undefined);
            setError(undefined);
            onFocus?.(e);
          }}
          value={
            internalValue?.replace(
              /[^a-z\u00c0-\u024f\u1e00-\u1eff0-9 \-\_\,']+/gi,
              ''
            ) ??
            value?.replace(/[^a-z\u00c0-\u024f\u1e00-\u1eff0-9 \-\_\,']+/gi, '')
          }
          onKeyDown={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              e.preventDefault();
            }
          }}
        />

        {!focused && (successed || valid) && (
          <base-icon icon="check-circle-fill" class="icon-state success" />
        )}
        {!focused && (error || valid === false) && (
          <base-icon icon="info-fill" class="icon-state error" />
        )}

        {focused &&
          internalValue &&
          internalValue !== null &&
          internalValue.trim() !== '' && (
            <base-icon
              icon="cross"
              class="icon-state neutral"
              onMouseDown={onResetField}
            />
          )}
      </div>
      {!focused && (error || valid === false) && (
        <>
          <div className="autocomplete-error">
            {isStringEmpty(internalValue) &&
              (emptyErrorMessage ?? 'Champ obligatoire')}
            {!isStringEmpty(internalValue) &&
              (invalidErrorMessage ?? 'Veuillez choisir dans la liste')}
          </div>
          <div className="autocomplete-other-cmp">
            <div onClick={onSuggestionClick}>{suggestionLabel}</div>
          </div>
        </>
      )}

      {showResult && (
        <div className="content-result">
          <div className="content-result-items">
            {result?.map((res: any, index) => (
              <div
                key={`autocomplete_item_${index}`}
                className="content-item"
                onMouseDown={(e: React.MouseEvent<HTMLElement>) => {
                  const concatenedRes = concantenedObjectWithComma({
                    localite: res.localite,
                    codePostal: res.codePostal,
                    pays: res.pays,
                  });
                  setInternalValue(concatenedRes);
                  setSelected(res);
                  onChange?.(res, concatenedRes);
                }}
              >
                <span className="autocomplete-main-item">
                  {highlightKeywords(toHighlight, res.localite)}
                </span>
                {(res.codePostal || res.pays) && (
                  <span className="autocomplete-second-item">
                    {res.codePostal && (
                      <>{highlightKeywords(toHighlight, res.codePostal)}, </>
                    )}
                    {res.pays && highlightKeywords(toHighlight, res.pays)}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className="autocomplete-other-cmp">
            <div onMouseDown={onSuggestionClick}>{suggestionLabel}</div>
          </div>
        </div>
      )}
    </AutoCompleteFieldWrapper>
  );
};

export default AutoCompleteField;
