import { ReactNode, useEffect, useRef } from 'react';

import { RadioButtonWrapper } from './styles';
import { RadioButtonCardDS } from '../DesignSystem';
import { t } from 'i18next';

export interface IProps {
  title: string;
  subtitle?: string;
  price?: string;
  description?: ReactNode;
  categorie?: string;
  linkText?: string;
  cardKey?: string;
  isRecommended?: boolean;
  recommandedTitle?: string;
  selected?: boolean | null;
  value?: string;
  checked?: boolean;
  onSelectedChange?: (
    e: CustomEvent & {
      target: {
        value: any;
      };
    }
  ) => void;
  onLinkClick: (value?: string) => void;
}

const insuranceTitle = {
  DIMC: {
    label: 'Formule DIMC',
    content: {
      totallyInCharged: ['Décès', 'Invalidité'],
      monthlyInCharged: ['Maladie', 'Chômage'],
    },
  },
  DIM: {
    label: 'Formule DIM',
    content: {
      totallyInCharged: ['Décès', 'Invalidité'],
      monthlyInCharged: ['Maladie'],
    },
  },
  D: {
    label: 'Formule D',
    content: {
      totallyInCharged: ['Décès'],
    },
  },
};

const NO_ASSU = 'Sans assurance';

const NO_ASSU_COVER = [
  {
    icon: '/images/death-cross.svg',
    description: t('insurance.no_assu.death'),
  },
  {
    icon: '/images/invalid-cross.svg',
    description: t('insurance.no_assu.autonomy'),
  },
  {
    icon: '/images/accident-cross.svg',
    description: t('insurance.no_assu.invalidity'),
  },
  {
    icon: '/images/job-cross.svg',
    description: t('insurance.no_assu.jobloss'),
  },
];

export const CardWithHeader: React.FC<IProps> = ({
  cardKey,
  recommandedTitle,
  onSelectedChange,
  selected,
  title,
  price,
  linkText,
  onLinkClick,
}: IProps) => {
  const cardRef = useRef<RadioButtonCardDS>(null);

  useEffect(() => {
    cardRef.current.setAttribute('title', recommandedTitle);
    const divTitleStyle = cardRef.current.shadowRoot.querySelector(
      'div[name="title"]'
    ) as HTMLDivElement;
    divTitleStyle.style.setProperty('font-family', 'Open Sans Bold');
  }, [cardRef.current]);

  const insuranceItem = insuranceTitle[title];

  return (
    <RadioButtonWrapper
      value={cardKey}
      title={recommandedTitle}
      onSelectedChange={onSelectedChange}
      checked={selected ? true : undefined}
      tabIndex={0}
      ref={cardRef}
      className={
        cardKey !== NO_ASSU
          ? `with-assu ${selected ? 'selected' : ''}`
          : `no-assu ${selected ? 'selected' : ''}`
      }
    >
      {title && (
        <span slot="title">
          {insuranceItem?.label ?? title}
          {cardKey !== NO_ASSU ? '*' : ''}
        </span>
      )}

      {cardKey !== NO_ASSU && (
        <div slot="acronym">
          <div className="totally-in-charged">
            <span className="subtitle">
              {t('insurance.labels.totallyInCharge')}
            </span>
            {(insuranceItem?.content.totallyInCharged ?? []).map(
              (item: string, index: number) => (
                <span
                  key={`totally_in_charged_` + index + 1}
                  className={`item ${selected ? 'selected' : ''}`}
                >
                  {item}
                </span>
              )
            )}
          </div>
          {insuranceItem?.content.monthlyInCharged?.length > 0 && (
            <div className="monthly-in-charged">
              <span className="subtitle">
                {t('insurance.labels.monthlyInCharge')}
              </span>
              {(insuranceItem?.content.monthlyInCharged ?? []).map(
                (item: string, index: number) => (
                  <span
                    key={`monthly_in_charged_` + index + 1}
                    className={`item ${selected ? 'selected' : ''}`}
                  >
                    {item}
                  </span>
                )
              )}
            </div>
          )}
        </div>
      )}

      {cardKey === NO_ASSU && selected && (
        <div slot="acronym">
          {NO_ASSU_COVER.map((item, index) => (
            <div className="no-assu-cover" key={`no_assu_cover_` + index + 1}>
              <img src={item.icon} alt={item.icon} />
              <p className="no-assu-description">{item.description}</p>
            </div>
          ))}
        </div>
      )}

      {price && (
        <span slot="pricetag">{(price ?? '').replace('.', ',')} € / mois</span>
      )}
      {cardKey !== NO_ASSU && (
        <a slot="link" onClick={() => onLinkClick?.(title)}>
          {linkText}
        </a>
      )}
    </RadioButtonWrapper>
  );
};
