import { BaseButton } from '@sweb-front/components';
import { Trans, useTranslation } from 'react-i18next';
import { selectNavigation, useAppSelector } from '@sweb-front/store';
import {
  ContentWrapper,
  DocumentInPersonalAccountParagraph,
  DocumentInPersonalAccountParagraphExplanation,
  ImgWrapper,
  SectionWrapper,
  AcceptedLoan,
  TitleCmp,
  CustomizedValidateButtonWrapper,
  DocumentReceived,
  CustomedBubbleInfoCmp,
  AsteriskWrapper,
} from './styles';
import useApprovedPayment from './useApprovedPayment';

const ApprovedPayment = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const navigation = useAppSelector(selectNavigation);
  const { onContractClick } = useApprovedPayment(
    opportunity,
    parameters,
    navigation
  );
  const { wayType } = parameters;

  return (
    <SectionWrapper>
      <ImgWrapper>
        <img
          width="152"
          height="160"
          src="/images/cetelem-success.png"
          alt={t('payment_success')}
        />
      </ImgWrapper>
      <ContentWrapper>
        <TitleCmp level={3}>{t('responses.accepted.congratulation')}</TitleCmp>
        <AcceptedLoan>{t('responses.accepted.definitely')}</AcceptedLoan>
        <DocumentReceived>
          <Trans
            i18nKey="responses.accepted.documentReceived"
            components={{
              contractspace: (
                <span>{t('responses.accepted.downloadDocumentsBold')}</span>
              ),
            }}
          />
        </DocumentReceived>
        <CustomedBubbleInfoCmp
          hasIcon={true}
          hasBorder={false}
          icon="information-lamp"
          descriptionCls="blue-color"
          description={
            <Trans
              i18nKey="responses.accepted.downloadDocuments"
              components={{
                contractspace: (
                  <span>{t('responses.accepted.downloadDocumentsBold')}</span>
                ),
              }}
            />
          }
        ></CustomedBubbleInfoCmp>
        <CustomizedValidateButtonWrapper>
          <BaseButton
            onClick={onContractClick}
            id="contract-download-button"
            secondary
          >
            {t('responses.buttons.contractArea')}
          </BaseButton>
        </CustomizedValidateButtonWrapper>
        <DocumentInPersonalAccountParagraph>
          {t('responses.accepted.documentInPersonalAccount')}
        </DocumentInPersonalAccountParagraph>
        {wayType && (
          <DocumentInPersonalAccountParagraphExplanation>
            {t(`responses.accepted.${wayType}.getContractCopy`)}
          </DocumentInPersonalAccountParagraphExplanation>
        )}

        <AsteriskWrapper>
          <Trans
            i18nKey="responses.accepted.asterix"
            components={{
              website: <a href="https://www.cetelem.fr" target="_blank"></a>,
            }}
          />
        </AsteriskWrapper>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default ApprovedPayment;
