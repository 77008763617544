import styled from 'styled-components';

export const NumericListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
`;
export const NumberWrapper = styled.div`
  font-family: 'Ubuntu Bold';
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: inline-block;
  width: 2.8rem;
  height: 2.8rem;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  background-color: #f2f3f3;
  vertical-align: middle;
  padding: 0.1rem;
`;
export const ContentWrapper = styled.div`
  margin-left: 0.8rem;
  flex: 80%;
`;
export const TitleWrapper = styled.div`
  margin-top: 0.14rem;
  margin-bottom: 0.24rem;
  font-size: 1.6rem;
  font-family: 'Open Sans Bold';
  line-height: 2.4rem;
`;
export const DescriptionWrapper = styled.div`
  font-size: 1.6rem;
  font-family: 'Open Sans';
  color: var(--neutrals-500);
  line-height: 2.4rem;
`;
