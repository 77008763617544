import { BaseButton, NumericList } from '@sweb-front/components';
import { Trans, useTranslation } from 'react-i18next';
import { selectNavigation, useAppSelector } from '@sweb-front/store';

import {
  AsteriskWrapper,
  BackButtonWrapper,
  DocumentInPersonalAccountParagraph,
  FirstParagraph,
  ImgWrapper,
  Paragraph,
  PrintAndDownloadParagraph,
  PrintAndDownloadParagraphBold,
  SectionWrapper,
  SeparatorWrapper,
  TitleCmp,
} from './styles';
import useApprovedLoan from './useApprovedLoan';

const ApprovedLoan = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const navigation = useAppSelector(selectNavigation);
  const { onContractClick } = useApprovedLoan(
    opportunity,
    parameters,
    navigation
  );

  return (
    <SectionWrapper>
      <ImgWrapper>
        <base-icon icon="timing" />
      </ImgWrapper>
      <TitleCmp>{t('responses.approvedLoan.congratulations')}</TitleCmp>
      <FirstParagraph>
        {t('responses.approvedLoan.documentReceived')}
      </FirstParagraph>
      <Paragraph>
        <strong>{t('responses.approvedLoan.nextStep')}</strong>
      </Paragraph>
      <SeparatorWrapper>
        <NumericList
          listIndex="1"
          title={t('responses.approvedLoan.step1.title')}
          description={t('responses.approvedLoan.step1.description')}
        />
      </SeparatorWrapper>
      <SeparatorWrapper>
        <NumericList
          listIndex="2"
          title={t('responses.approvedLoan.step2.title')}
          description={t('responses.approvedLoan.step2.description')}
        />
      </SeparatorWrapper>
      <SeparatorWrapper>
        <NumericList
          listIndex="3"
          title={t('responses.approvedLoan.step3.title')}
          description={t('responses.approvedLoan.step3.description', {
            enseigne: parameters.shopSign,
          })}
        />
      </SeparatorWrapper>
      <PrintAndDownloadParagraph>
        {t('responses.approvedLoan.downloadDocuments1')}
        <PrintAndDownloadParagraphBold>
          {t('responses.approvedLoan.downloadDocumentsBold')}
        </PrintAndDownloadParagraphBold>
      </PrintAndDownloadParagraph>
      <BackButtonWrapper>
        <BaseButton
          onClick={onContractClick}
          id="contract-download-button"
          secondary
        >
          <base-icon />
          {t('responses.buttons.contractArea')}
        </BaseButton>
      </BackButtonWrapper>
      <DocumentInPersonalAccountParagraph>
        {t('responses.approvedLoan.documentInPersonalAccount')}
      </DocumentInPersonalAccountParagraph>
      <Paragraph>{t(`responses.approvedLoan.getContractCopy`)}</Paragraph>
      <AsteriskWrapper>
        <Trans
          i18nKey="responses.accepted.asterix"
          components={{
            website: <a href="https://www.cetelem.fr" target="_blank"></a>,
          }}
        />
      </AsteriskWrapper>
    </SectionWrapper>
  );
};

export default ApprovedLoan;
