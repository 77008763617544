import { ReactNode } from 'react';
import {
  CustomedBubbleInfoWrapper,
  ImgWrapper,
  StyledCloseIcon,
} from './styles';

export interface ICustomedBubbleInfoProps {
  id?: string;
  description?: ReactNode;
  descriptionCls?: string;
  descriptionComp?: ReactNode;
  hasIcon?: boolean;
  hasClosebtn?: boolean;
  hasBorder?: boolean;
  icon?: string;
  onClose?: () => void;
  className?: string;
}

const CustomedBubbleInfo = ({
  id,
  description,
  descriptionComp,
  hasIcon = true,
  hasClosebtn = false,
  hasBorder = true,
  icon = 'info-fill',
  descriptionCls,
  className,
  onClose,
}: ICustomedBubbleInfoProps) => {
  return (
    <CustomedBubbleInfoWrapper
      className={className}
      id={id}
      hasBorder={hasBorder}
    >
      {hasIcon && (
        <ImgWrapper>
          <base-icon icon={icon ?? 'info-fill'} />
        </ImgWrapper>
      )}
      <div className={`bubbleinfo-description ${descriptionCls}`}>
        {description}
        {descriptionComp}
      </div>
      {hasClosebtn && (
        <ImgWrapper onMouseDown={onClose} className="remove-btn">
          <StyledCloseIcon strokeWidth={1} />
        </ImgWrapper>
      )}
    </CustomedBubbleInfoWrapper>
  );
};

export default CustomedBubbleInfo;
