import { IFileDownloader } from '@sweb-front/types';
import FileDownload from '../FileDowload';
import Title from '../Title';
import { ContentWrapper } from './styles';

export interface IDocumentsProps {
  title?: string;
  files?: IFileDownloader[];
  className?: string;
}
const Documents: React.FC<IDocumentsProps> = ({
  title,
  files,
  className,
}: IDocumentsProps) => (
  <>
    <Title level={3} className={className}>
      {title}
    </Title>
    <ContentWrapper className="content">
      {files?.map((file, index) => (
        <FileDownload file={file} key={`file_${index + 1}`} />
      ))}
    </ContentWrapper>
  </>
);

export default Documents;
