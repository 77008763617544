import styled, { createGlobalStyle } from 'styled-components';
import Loader from '../components/Loader';

const GlobalStyle = createGlobalStyle`
  html {
    font-family: 'Open Sans', sans-serif;
    font-size: 62.5%; /* 1rem = 10px */
    height: 100%;
  }
  input, select, textarea, button {
    font-family: inherit;
  }

  h1,h2,h3 {
    font-family: 'Ubuntu';
  }
  div, span{
    font-family: 'Open Sans';
    color: var(--neutral-700);
    text-transform: none;
  }

  body {
    width: 100%;
    padding: 0;
    margin: 0;
    line-height: 1.5;
    font-size: 1.5rem;

    &.test-mode {
      border: 8px solid #4a90e2;

      .test-mode-top, .test-mode-bottom {
        background: #4a90e2;
        position: fixed;
        width: 100%;
        z-index: 10;
        height: 8px;
      }
      .test-mode-top {
        top: 0;
      }
      .test-mode-bottom {
        bottom: 0;
      }

      .app {
        min-height: calc(100vh - 2 * 8px);
      }
    }
  }

  * {
    box-sizing: border-box;
  }

  #app {
    background-color: #fff;
    height: 100%;
  }

  /* Style for PerfectScrollbar : alwars on the right */
  .ps__rail-y {
    left: auto !important;
  }
`;

export const LoadingWrapper = styled.div`
  margin: 50% 0 0 0;
`;

export const StyledButtonLoader = styled(Loader)`
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  width: 2.4rem;
  height: 2.4rem;
`;

export const HelperMessageWrapper = styled.div`
  margin-top: 0.4rem;
  padding: 1.4rem;
  position: relative;
  gap: 0.8rem;
  border-radius: 0.4rem;
  opacity: 0rem;
  background: #e9f2fb;
  font-family: Open Sans;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: left;
  color: #0a6bbf;
  & span {
    font-family: Open Sans Bold;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.01em;
    text-align: left;
  }
`;

export default GlobalStyle;
