import axios, { AxiosInstance } from 'axios';
import mem from 'memoizee';
import { ERRORDETAILS } from './constants';
import { ERRORKEY } from '@vat/configuration';
export interface IAppConfig {
  msBffBaseURL: string;
  timeout: number;
  env: string;
  apimKey: string;
  api: IAppApiConfig;
  psp: Record<string, any>;
}

export interface IAppApiConfig {
  openBankingRequest: IAppOpenBanking;
}

export interface IAppOpenBanking {
  basePath: string;
  init: string;
  result: string;
}

declare global {
  interface WindowWithEnv extends Window {
    _env_: IAppConfig;
  }
}

export default function axiosInstance(): AxiosInstance {
  return mem(
    (): AxiosInstance => {
      const axiosBack = axios.create({
        /* eslint no-underscore-dangle: 0 */
        baseURL: (window as unknown as WindowWithEnv)._env_?.msBffBaseURL,
        headers: {
          keyId: (window as unknown as WindowWithEnv)._env_?.apimKey,
        },
        timeout: 30000,
        timeoutErrorMessage: 'ECONNTIMEOUT',
      });

      axiosBack.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response === undefined) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(400);
          }
          const errCode = error.response.status;
          const resp = error.response.data;
          localStorage.setItem(ERRORKEY, resp?.status);
          localStorage.setItem(
            ERRORDETAILS,
            `Page: ${window.location.href} , Service: ${
              resp?.path ?? '-'
            }, Service Error: ${resp?.error ?? '-'}, Service Status Code: ${
              resp?.status ?? '-'
            }, Error Message: ${resp?.message ?? '-'}, $OtherReasons`
          );
          return Promise.reject(errCode);
        }
      );
      return axiosBack;
    },
    {
      primitive: true,
    }
  )();
}
